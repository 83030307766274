h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: $line-height-default;
}

h1 {
  font-size: $font-size-h1;
}
h2 {
  font-size: $font-size-h2;
}
h3 {
  font-size: $font-size-h3;
}
h4 {
  font-size: $font-size-h4;
}
h5 {
  font-size: $font-size-h5;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.text-primary {
  color: $color-primary !important;
}
.text-muted {
  color: $color-text-muted !important;
}
.text-bold {
  font-weight: bold;
}
.text-link {
  color: $color-link;
}

ul {
  list-style: none;
}
