@import '../../../../styles/variables';
.modal-button-container {
  margin: 1.5rem 0;
}

.view-more-modal {
  overflow: scroll;
  z-index: 999;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100vw;
  &__header {
    position: fixed;
    position: sticky;
    top: 0;
    height: 80px;
    width: 100%;
    z-index: 1001;
    display: flex;
    padding: 24px 16px 0px;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
    .view-more-modal__container {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      margin-top: 0;
      button {
        margin-left: auto;
        border: none;
        background: none;
        outline: none;
        transition: 0.5s ease;
        font-size: 25px;
        align-self: baseline;
        cursor: pointer;
        &:hover {
          color: $color-primary;
          transition: 0.5s ease;
        }
      }
      span {
        display: block;
        margin-right: 15px;
        cursor: pointer;
        height: 100%;
        font-weight: bold;
        &:hover {
          color: $color-primary-hover;
        }
        &.active {
          color: $color-primary;
          border-bottom: 4px solid $color-primary;
        }
      }
    }
  }
  &__container {
    max-width: 100vw;
    @media (min-width: $breakpoint-md) {
      max-width: 736px;
      margin: 40px auto 0;
    }
  }
  .title {
    display: flex;
    font-size: 24px;
    align-items: center;
    span {
      font-size: 24px;
      display: block;
    }
  }
  &__section {
    background-color: #fff;
    padding: 15px 15px;
    @media (min-width: $breakpoint-md) {
      padding: 20px 56px;
    }
    border-bottom: 1px solid $color-text-muted;
    pre {
      white-space: pre-wrap;
      font-family: $font-family-primary;
      line-height: $line-height-default;
      font-size: $font-size-default;
      height: fit-content;
      font-weight: $font-weight-normal;
    }
    .map {
      padding: 0 30px;
      img {
        width: 100%;
      }
    }
    &--partner {
      margin-top: -6px;
      padding-top: 30px;
      i {
        margin-right: 15px;
      }
      table {
        td:nth-of-type(2n + 1) {
          color: $color-text-muted;
          text-align: right;
          margin-right: 15px;
          display: block;
        }
      }
    }
    &--services {
      ul {
        margin: 0;
        width: 100%;
        padding: 0;
        li {
          background-color: rgba(255, 101, 80, 0.4);
          padding: 24px;
          display: flex;
          margin-bottom: 15px;
          &.included {
            background-color: #f3fdf6;
            .tag {
              background-color: #4eda79;
            }
          }
          .tag {
            font-size: $font-size-tiny;
            background-color: rgba(255, 49, 21, 1);
            color: white;
            margin-left: auto;
            display: block;
            max-width: 60px;
            text-align: center;
            padding: 5px;
            border-radius: 5px;
            height: 27px;
          }
        }
      }
    }
    &--facilities {
      display: flex;
      flex-wrap: wrap;
      h6 {
        flex-basis: 100%;
      }
    }
  }
  .facilities {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    div {
      flex-basis: 50%;
      font-size: 16px;
      display: flex;
      span {
        margin: 0 0 15px 15px;
        display: block;
      }
      i {
        font-size: 24px;
      }
    }
  }
  &__room {
    background-color: #fff;
    @media (min-width: $breakpoint-md) {
      margin-bottom: 30px;
    }
    img {
      width: 100%;
    }
    > div {
      padding: 15px 15px;
      @media (min-width: $breakpoint-md) {
        padding: 0 56px 20px;
      }
    }
  }
}

.modal-button {
  border: none;
  padding: 0;
  background-color: $color-text-light;
  color: $color-primary;
  font-size: $font-size-small;
}

.inner-modal-column {
  background-color: $color-text-light;
  padding: 1rem 3.5rem;
  .inner-container {
    padding: 1rem;
    margin: 0;
    .info-container {
      width: 100%;
      display: flex;
      p {
        margin: 0.5rem 0;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.5rem;
          margin-top: 1rem;
          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    }
    .info-list-container {
      width: 100%;
      display: flex;
      p {
        margin: 0.5rem 0;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.5rem;
          background-color: $color-background-green-light;
          margin-top: 1rem;
          &:first-of-type {
            margin-top: 0;
          }
        }
      }
      .green {
        background-color: $color-background-green;
        color: $color-text-light;
        padding: 0.25rem 0.5rem;
        font-size: small;
      }
      .grey {
        display: block;
        color: rgba(0, 0, 0, 0.5);
      }
      .bold {
        font-weight: bold;
      }
    }
    .info-icon-list-container {
      list-style: none;
      column-count: 2;
      margin: 0;
      padding: 0;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          list-style: none;
          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    }
  }
  p {
    text-align: justify;
    margin: 0;
  }
}

#separate {
  margin-top: 2rem;
}
