@import '../../styles/variables';

.checkbox-container {
  padding: 10px;
  font-size: $font-size-small;
  &.destination {
    display: grid;
    grid-gap: 10px;
    padding: 10px 0;
  }
  > label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: $font-weight-bold;
    &:after {
      transform-origin: 25% 50%;
      border: 6px solid transparent;
      border-width: 6px 9px;
      border-left-color: $steel-50;
      transform: rotate(90deg);
      width: 0;
      height: 0;
      display: inline-block;
      text-align: right;
      content: '';
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
  }
  .more {
    color: $color-primary;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.checkbox__list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;

  .checkbox__list-item {
    list-style-image: none;
    padding: 5px 0;
  }
}
