@import './variables';

label {
  p {
    margin: 0 0 4px 0;
  }
}

textarea,
select,
input[type='text'],
input[type='email'],
input[type='search'],
input[type='tel'],
input[type='url'],
input[type='password'],
input[type='color'],
input[type='file'],
input[type='color'],
input[type='number'],
input[type='range'],
input[type='date'],
input[type='month'],
input[type='week'],
input[type='time'],
input[type='datetime'],
input[type='datetime-local'] {
  padding: 0.75rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 100%;
  transition: border 0.15s ease-in-out;
  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.4);
    outline: none;
  }
}

input[type='file'] {
  border-width: 0;
  padding: 0;
}

input + *[class*='__error'] {
  position: relative;
  margin: -1px 0 0 0;
}
