@import '../../styles/variables';

.calendar-container {
  .icon {
    color: $color-text-light;
    font-size: 24px;
  }

  button {
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &[disabled] {
      color: $color-text-muted !important;
      cursor: default;
      font-weight: 400;
    }
  }

  border-radius: 0;
  box-shadow: 0 0 2.14rem 0 rgba(0, 0, 0, 0.3);
  box-sizing: content-box;
  display: block;
  font-size: 1.1rem;
  left: 45px;
  top: 5px;
  position: absolute;
  text-align: center;
  width: 25rem;
  z-index: 5;

  .react-calendar {
    background-color: $color-secondary;

    &__year-view__months {
      button {
        background-color: $color-secondary;
        color: $color-text-light;
        height: 40px;
        width: 60px;
      }
    }
  }

  .react-calendar__navigation {
    height: 3.55rem;
    padding: 1rem;
    background-color: $color-primary;
    justify-content: space-between;

    .react-calendar__navigation__label {
      background-color: $color-primary;
      border: none;
      width: auto;

      .react-calendar__navigation__label__labelText {
        color: $color-text-light;
        text-transform: uppercase;
        font-size: 1rem;
      }
    }

    .react-calendar__navigation__prev-button {
      background-color: $color-primary;
      border: none;
      height: 24px;
      width: 24px;
    }
    .react-calendar__navigation__next-button {
      background-color: $color-primary;
      border: none;
      height: 24px;
      width: 24px;
    }

    button {
      padding: 0;

      &[disabled] {
        display: none;
      }
    }
  }
  .react-calendar__month-view__weekdays {
    background-color: $color-secondary;
    padding: 1rem 1rem 0;
    .react-calendar__month-view__weekdays__weekday {
      abbr {
        color: $color-text-light;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }

  .react-calendar__month-view__days {
    background-color: $color-secondary;
    padding: 1rem;
    .react-calendar__month-view__days__day {
      color: $color-text-light;
      background-color: $color-secondary;
      border: 0.1rem solid #333;
      height: 42px;
      width: 42px;

      &--neighboringMonth {
        color: $color-text-muted;
      }

      button {
        margin: 1rem;
      }
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    left: 0;
  }

  .react-calendar__tile--active {
    background-color: $color-primary !important;
  }

  &::after {
    border-color: transparent transparent $color-primary transparent;
    border-style: solid;
    border-width: 12px;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    position: absolute;
    top: -24px;
    width: 0;
  }
}

.react-calendar__popover {
  display: block;
  height: 100%;
  width: 100%;
}
