@font-face {
  font-family: 'icons';
  src: url('/public/fonts/icons.ttf?0b4ce5a7f18f74d38b5b6e0704f7cc7a') format('truetype'),
    url('/public/fonts/icons.woff?0b4ce5a7f18f74d38b5b6e0704f7cc7a') format('woff'),
    url('/public/fonts/icons.woff2?0b4ce5a7f18f74d38b5b6e0704f7cc7a') format('woff2'),
    url('/public/fonts/icons.eot?0b4ce5a7f18f74d38b5b6e0704f7cc7a#iefix')
      format('embedded-opentype');
}

i[class^='icon-']:before,
i[class*=' icon-']:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-aircon:before {
  content: '\f101';
}
.icon-arrow_right:before {
  content: '\f102';
}
.icon-balcony:before {
  content: '\f103';
}
.icon-bar:before {
  content: '\f104';
}
.icon-bath:before {
  content: '\f105';
}
.icon-best-seller:before {
  content: '\f106';
}
.icon-bicycle:before {
  content: '\f107';
}
.icon-binoculars:before {
  content: '\f108';
}
.icon-calendar-checkmark:before {
  content: '\f109';
}
.icon-calendar:before {
  content: '\f10a';
}
.icon-checkmark_filled:before {
  content: '\f10b';
}
.icon-checkmark:before {
  content: '\f10c';
}
.icon-checkouts:before {
  content: '\f10d';
}
.icon-chevron_down:before {
  content: '\f10e';
}
.icon-chevron_left:before {
  content: '\f10f';
}
.icon-chevron_right:before {
  content: '\f110';
}
.icon-chevron_up:before {
  content: '\f111';
}
.icon-circled_chevron_down:before {
  content: '\f112';
}
.icon-circled_chevron_left:before {
  content: '\f113';
}
.icon-circled_chevron_right:before {
  content: '\f114';
}
.icon-circled_chevron_up:before {
  content: '\f115';
}
.icon-close:before {
  content: '\f116';
}
.icon-coffee-machine:before {
  content: '\f117';
}
.icon-cottilion:before {
  content: '\f118';
}
.icon-discount:before {
  content: '\f119';
}
.icon-facebook:before {
  content: '\f11a';
}
.icon-facilities:before {
  content: '\f11b';
}
.icon-front-desk:before {
  content: '\f11c';
}
.icon-google:before {
  content: '\f11d';
}
.icon-gym:before {
  content: '\f11e';
}
.icon-heart_empty:before {
  content: '\f11f';
}
.icon-heart-thin:before {
  content: '\f120';
}
.icon-heart:before {
  content: '\f121';
}
.icon-hot_price:before {
  content: '\f122';
}
.icon-hot-bath:before {
  content: '\f123';
}
.icon-hotel:before {
  content: '\f124';
}
.icon-info:before {
  content: '\f125';
}
.icon-instagram:before {
  content: '\f126';
}
.icon-kitchenette:before {
  content: '\f127';
}
.icon-lift:before {
  content: '\f128';
}
.icon-local-parking:before {
  content: '\f129';
}
.icon-luggage:before {
  content: '\f12a';
}
.icon-marker:before {
  content: '\f12b';
}
.icon-minifridge:before {
  content: '\f12c';
}
.icon-outlined_chevron_down:before {
  content: '\f12d';
}
.icon-outlined_chevron_left:before {
  content: '\f12e';
}
.icon-outlined_chevron_right:before {
  content: '\f12f';
}
.icon-outlined_chevron_up:before {
  content: '\f130';
}
.icon-outlined_info:before {
  content: '\f131';
}
.icon-outlined_user:before {
  content: '\f132';
}
.icon-parking-bicycle:before {
  content: '\f133';
}
.icon-parking:before {
  content: '\f134';
}
.icon-percent:before {
  content: '\f135';
}
.icon-percentage-discount:before {
  content: '\f136';
}
.icon-pets-not-allowed:before {
  content: '\f137';
}
.icon-pets:before {
  content: '\f138';
}
.icon-playground:before {
  content: '\f139';
}
.icon-pool-indoor:before {
  content: '\f13a';
}
.icon-pool-outdoor:before {
  content: '\f13b';
}
.icon-private-bathroom:before {
  content: '\f13c';
}
.icon-restaurant:before {
  content: '\f13d';
}
.icon-ring:before {
  content: '\f13e';
}
.icon-room-service:before {
  content: '\f13f';
}
.icon-safebox:before {
  content: '\f140';
}
.icon-sauna:before {
  content: '\f141';
}
.icon-search_type_hotel:before {
  content: '\f142';
}
.icon-search:before {
  content: '\f143';
}
.icon-shield:before {
  content: '\f144';
}
.icon-shower:before {
  content: '\f145';
}
.icon-smoking-no:before {
  content: '\f146';
}
.icon-smoking-yes:before {
  content: '\f147';
}
.icon-spa:before {
  content: '\f148';
}
.icon-tea-facilities:before {
  content: '\f149';
}
.icon-thumbs:before {
  content: '\f14a';
}
.icon-toiletries:before {
  content: '\f14b';
}
.icon-tv:before {
  content: '\f14c';
}
.icon-user:before {
  content: '\f14d';
}
.icon-views:before {
  content: '\f14e';
}
.icon-wheelchair-inaccessible:before {
  content: '\f14f';
}
.icon-wifi:before {
  content: '\f150';
}
