@import '../../../styles/variables';

.bestsellers-card {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 10px;

  &__link {
    &:hover {
      text-decoration: none;

      .bestsellers-card__image {
        opacity: 0.9;
      }
    }
  }

  &__image-content {
    position: relative;
  }

  &__image {
    width: 100%;
  }

  &__discount-tag {
    position: absolute;
    top: 14px;
    color: $color-background-light;
    background-color: $color-primary;
    height: 36px;
    width: 60px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 24px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -35px;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent transparent transparent $color-primary;
      border-width: 18px;
      margin-right: 0;
    }

    @media (min-width: $breakpoint-sm) {
      height: 50px;
      width: 110px;
      font-size: 34px;
      top: 20px;

      &:after {
        right: -49px;
        border-width: 25px;
      }
    }
  }

  &__rating {
    position: absolute;
    right: 14px;
    top: 14px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #ccc;
    padding: 8px 8px 1px;
    font-size: 1.2rem;

    &-summary {
      font-size: 10px;
      width: 5rem;
    }

    &-average {
      float: left;
      line-height: 1;

      small {
        font-size: 1rem;
      }
    }
    &-recommendations {
      float: right;
      line-height: 1;
      padding-left: 4px;

      strong {
        color: $color-primary;
      }
    }
    &-text-summary {
      font-size: 1.2rem;
      text-align: center;
      line-height: 2;
    }

    @media (min-width: $breakpoint-sm) {
      font-size: 1.8rem;
      padding: 12px 12px 2px;
      right: 20px;
      top: 20px;

      &-text-summary {
        font-size: 1.5rem;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 0 0;
    flex-flow: wrap;
  }

  &__title {
    color: #666;
    line-height: 1.2;
    font-size: $font-size-medium;

    @media (min-width: $breakpoint-sm) {
      font-size: 1.75rem;
    }
  }

  &__description {
    margin-bottom: 20px;
  }

  &__location,
  &__rate {
    color: #999;
    font-size: $font-size-small;
  }

  &__price-container {
    display: flex;
    text-align: end;
    align-items: center;
    margin-left: auto;
  }

  &__price {
    color: $color-primary;
    font-size: $font-size-medium;
    line-height: 1;

    @media (min-width: $breakpoint-sm) {
      font-size: 1.75rem;
    }
  }

  &__price-info {
    font-size: $font-size-small;
    color: #999;
  }

  &__button-wrapper {
    width: 200px;
    padding-left: 20px;
  }
}
