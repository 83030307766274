@import '../../styles/variables';
.listing-page-container {
  padding: 5rem 0;
}

.hero-header-img {
  margin-right: 4rem;
}
.hero-header-title {
  margin-bottom: 0.5rem;
  text-align: center;
  font-size: 3rem;
}

.hero-header-pivot-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.hero-header-pivot-link {
  padding: 0.25rem 0.5rem;
  border-radius: 2px;
  background-color: $color-text-light;
  font-size: 0.68rem;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: $color-background-blue;
  border: 1px solid $color-background-blue-light;
  &:hover {
    background-color: $color-background-blue;
    color: $color-text-light;
    border-color: $color-background-blue;
    text-decoration: none;
  }
}

.offer-item {
  display: flex;
  flex-wrap: wrap;
  &:hover {
    text-decoration: none;
  }
  @media (min-width: $breakpoint-md) {
    margin-bottom: 20px;
  }
  &__image-container {
    position: relative;
    padding: 0 !important;
    overflow: hidden;
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
    }
    @media (min-width: $breakpoint-md) {
      min-height: 192px;
    }
    display: inline-block;
    height: auto;
    img {
      display: block;
      min-height: 192px;
      height: 100%;
      width: 100%;
      object-fit: fill;
    }
  }
  &__description-container {
    border: 1px solid rgb(224, 224, 224);
    border-left: none;
    padding: 0 !important;
    @media (max-width: $breakpoint-md) {
      border: none;
      width: 100%;
      margin: -19px 0 15px 0;
      border-bottom: 1px solid $color-background-lighter;
    }
  }
  .description {
    padding: 10px 15px;
    background-color: $color-body-background;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    position: relative;
    @media (max-width: $breakpoint-md) {
      padding: 15px;
    }
  }
  .prices-wrapper {
    min-height: 50px;
    justify-content: flex-end;
    display: flex;
    font-size: $font-size-small;
    margin-top: auto;
    &__content {
      margin-right: 15px;
      @media (max-width: $breakpoint-md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
      }
    }
    .prices-item {
      margin: 0;
      padding: 0;
      color: $color-background-darker;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      font-size: 14px;
      @media (max-width: $breakpoint-md) {
        font-size: $font-size-small !important;
        justify-content: flex-start;
      }
    }
    .old-price {
      text-decoration: line-through;
      margin: 0 5px 0 5px;
      @media (max-width: $breakpoint-md) {
        display: none;
      }
    }
    .main-price {
      margin: 0 0 -2px 5px;
      @media (min-width: $breakpoint-md) {
        color: $color-primary;
      }
      .price {
        color: $color-primary;
        font-weight: bold;
        font-size: 24px;
      }
    }
  }
  .title {
    align-self: flex-start;
    font-size: $font-size-small;
    max-width: 90%;
    line-height: 1.3em;
    @media (max-width: $breakpoint-md) {
      max-width: 80%;
    }
    h4 {
      font-size: 15px;
    }
    h5 {
      font-weight: normal;
    }
  }
  .button-wrapper {
    margin: auto 0;
    @media (max-width: $breakpoint-md) {
      margin: auto 0 0 0;
      font-weight: bolder;
    }
    a {
      color: $color-background-light;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .discount-tag {
    position: absolute;
    top: 15px;
    @media (max-width: $breakpoint-md) {
      left: 0;
    }
    color: $color-background-light;
    background-color: $color-primary;
    height: 36px;
    width: 60px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    line-height: 36px;
    font-size: 18px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      right: -35px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-color: transparent transparent transparent $color-primary;
      border-width: 18px;
      margin-right: 0px;
    }
  }
  .features {
    align-self: flex-start;
    margin-top: 5px;
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    span {
      font-size: small;
      display: block;
      margin-bottom: 2px;
      color: $color-background-darker;
      position: relative;
      padding-left: 15px;
      &:before {
        font-weight: bold;
        content: '\2713';
        display: inline-block;
        position: absolute;
        left: 2px;
      }
    }
  }
  .urgency {
    line-height: $line-height-small;
    span {
      font-size: $font-size-tiny;
      font-weight: bold;
      text-align: left;
      color: $color-primary;
    }
  }
  .badges {
    align-self: flex-start;
    font-size: $font-size-small;
    display: flex;
    flex-wrap: wrap;
    max-width: 80%;
    &__item {
      padding: 3px 2px;
      height: 22px;
      margin: 0 3px 1px 0;
      display: flex;
      align-items: center;
      span {
        margin-right: 5px;
      }
      img {
        height: 100%;
        width: auto;
      }
    }
  }
  .ratings {
    font-size: $font-size-tiny;
    position: absolute;
    right: 16px;
    top: 65px;
    text-align: right;
    font-weight: $font-weight-medium;
    color: $color-text-muted;
    div:first-of-type {
      color: $color-primary;
      i {
        color: $color-text-muted;
        margin-right: 2px;
      }
    }
  }
}
