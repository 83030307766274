@import '../../../styles/variables';
.divider {
  padding: 0;
  background-color: $color-light-border;
  width: 1px;
  margin: 2%;
  @media (max-width: 480px) {
    display: none;
  }
}
