* {
  box-sizing: border-box;
}

body {
  background: $color-body-background;
  font-family: $font-family-primary;
  font-size: $font-size-default;
  color: $color-text-primary;
  line-height: $line-height-default;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  position: relative;
  margin: 0;
}

input[type='text'],
input[type='number'],
input[type='date'],
input[type='email'] {
  padding: 0.75rem 1rem;
}
