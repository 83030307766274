@import '../../../styles/variables';

.row-container {
  padding: 2rem 0;
  border: none;
}

.row-container-border {
  padding: 2rem;
  margin-bottom: 0;
  border: 1px solid $color-light-border;
  span {
    margin-bottom: 1rem;
  }
}

.offer-paragraph-container {
  height: 8.7rem;
  overflow: hidden;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 25%;
    height: 1.5rem;
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0) 0,
      hsla(0, 0%, 100%, 0.01) 1%,
      $color-text-light
    );
  }
}

.container-button {
  padding: 2rem;
  margin: auto;
}

.search-controls-button {
  padding: 1rem 0;
}

.label {
  text-align: left;
  padding: 1rem 0;
}

.label-white {
  text-align: left;
  color: white;
  font-size: small;
  margin: 1rem 0;
}

.value {
  padding: 1rem 0;
}

.orange-unordered-list {
  column-count: 2;
  list-style: none;
  padding: 0;
  li {
    padding: 0 0 0.625rem;
    &:before {
      content: '\2022';
      color: $color-primary;
      font-weight: bold;
      display: inline-block;
      width: 0.5rem;
      padding-right: 1rem;
    }
  }
}

.search-controls-container {
  width: 100%;
  background-color: $color-secondary;
  font-weight: bold;
  padding: 5%;
  color: white;
  Col {
    width: 100%;
    margin-bottom: 5%;
    label {
      font-size: small;
      display: inline-block;
      input {
        width: 1.5rem;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
}

.big-icon {
  font-size: x-large;
  margin: auto;
}

.search-controls-bar {
  color: $color-text-muted;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  height: 2.5rem;
  margin: 0.7px 0;
  background-color: $color-text-light;
}

.prices-container {
  min-height: 3rem;
  p {
    color: $steel;
    font-size: larger;
    margin-right: 2rem;
  }
  .crossed-price {
    color: $steel-50;
    text-decoration: line-through;
  }
  .main-price {
    text-decoration: none;
    color: $color-primary;
    font-size: larger;
    font-weight: 700;
  }
  @media (max-width: 480px) {
  }
}

.subtitle {
  color: $steel-50;
  font-weight: normal;
}

.discount {
  display: inline-block;
  vertical-align: middle;
  margin: 0 1.5rem 0.5rem 1rem;
  padding: 0.25rem 0.5rem;
  background: $color-primary-red;
  border-radius: 2px;
  color: $color-text-light;
  line-height: 1.5;
  letter-spacing: -0.1px;
}

.price-button {
  a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 480px) {
    font-weight: bolder;
  }
}

.primary-button-container {
  display: inline-flex;
  padding: 1rem;
  background: $color-primary;
  color: $color-text-light;
  justify-content: center;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
  align-items: center;
  &:hover {
    background: $color-primary-red;
  }
  div {
    display: flex;
    margin-top: -1px;
    margin-left: 3px;
    margin-right: 3px;
    align-items: center;
  }
}
