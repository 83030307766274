@import '../../styles/variables';

.bestsellers {
  max-width: 1140px;
  margin: 0 auto;
  padding: 10px;

  &__header {
    font-size: 1.75rem;
    color: #666;
  }

  &__section-divider {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border: 0;
    height: 0.35rem;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

@import './components/Card';
