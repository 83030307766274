@import '../../styles/variables';
.loader,
.loader:after {
  border-radius: 50%;
  width: 6em;
  height: 6em;
}
.loader {
  margin: 4rem auto;
  font-size: 1rem;
  position: relative;
  text-indent: -9999em;
  border-top: 0.38rem solid rgba(255, 255, 255, 0.75);
  border-right: 0.38rem solid rgba(255, 255, 255, 0.75);
  border-bottom: 0.38rem solid rgba(255, 255, 255, 0.75);
  border-left: 0.38rem solid $color-primary;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader 1.1s infinite linear;
  animation: loader 1.1s infinite linear;
}
@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
