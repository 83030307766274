@import '../../styles/variables';

.offer-map-container {
  position: relative;
  .modal-trigger {
    margin: 25px 0;
  }
}

.offer-map {
  @media (max-width: $breakpoint-md) {
    max-height: 480px;
  }
}

.offer-map-card {
  position: absolute;
  top: auto;
  right: 16px;
  bottom: 16px;
  left: 16px;
  overflow: hidden;
  width: auto;

  @media (min-width: $breakpoint-sm) {
    left: auto;
    width: 288px;
    height: auto;
  }

  @media (min-width: $breakpoint-md) {
    right: 124px;
    bottom: 80px;
    width: 352px;
  }
}

@import './components/Card/Card';
@import './components/Urgency/Urgency';
@import './components/ViewMoreModal/Modal';
@import './components/Navbar';
@import './components/Sections';
