@import '../../styles/variables';
@import './components/ClearFilters';

.home-page {
  background: $color-body-section-background;
  .offers-count-container {
    .filters-container {
      display: flex;
    }
    .sort-container {
      @media (max-width: 480px) {
        display: none;
      }
    }
  }
  @media (max-width: $breakpoint-sm) {
    .container {
      padding: 0;
    }
  }
}
