@import 'functions';
$base-space: 1rem;
// COLORS
$color-primary: #e04f00;
$color-primary-hover: #c74600;
$color-primary-red: #fb2400;
$color-primary-light: #fbe6dd;
$color-secondary: #333333;
$color-body-background: #fdfdfd;
$color-body-section-background: #f5f5f5;
$color-text-primary: #333333;
$color-text-muted: #666666;
$color-text-muted-lighter: #6f6e6e;
$color-text-muted-lighter-purple: #696b79;
$color-text-light: #ffffff;
$color-light-border: #ebebeb;
$color-background-lighter: #d8d8d8;
$color-background-light: #f2f2f2;
$color-background-darker: #404040;
$color-background-green-light: #f3fdf6;
$color-background-green: #4eda79;
$color-background-blue: #0a8de9;
$color-background-blue-light: #48aaef;
$color-background-purple-dark: #282b40;
$color-link: $color-background-blue; // TODO: refactor that alias
// Steel - copied from TB
$steel: #282b40;
$steel-70: #5e6070;
$steel-50: #93959f;
$steel-30: #bebfc5;
$steel-15: #dfdfe3;
$steel-10: #e9e9eb;
$steel-5: #f4f4f5;

// TYPOGRAPHY
$font-family-primary: Lato, Arial, Helvetica, sans-serif;
$line-height-default: 1.5;
$line-height-small: 1.4;
$line-height-large: 1.6;
$font-size-default: getFontSize(1);
$font-size-tiny: getFontSize(0.7);
$font-size-small: getFontSize(0.88);
$font-size-medium: getFontSize(1.5);
$font-size-large: getFontSize(3);
$font-size-h1: getFontSize(2);
$font-size-h2: getFontSize(1.75);
$font-size-h3: getFontSize(1.5);
$font-size-h4: getFontSize(1.25);
$font-size-h5: getFontSize(1);
$font-size-h6: getFontSize(0.75);
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;
// RESPONSIVE
$breakpoint-sm: 48rem;
$breakpoint-md: 64rem;
$breakpoint-lg: 80rem;
