$space-1: $base-space * 0.25;
$space-2: $base-space * 0.5;
$space-3: $base-space;
$space-4: $base-space * 1.5;
$space-5: $base-space * 3;

// PADDINGS

.p0 {
  padding: 0;
}
.pt0 {
  padding-top: 0;
}
.pr0 {
  padding-right: 0;
}
.pb0 {
  padding-bottom: 0;
}
.pl0 {
  padding-left: 0;
}
.px0 {
  padding-left: 0;
  padding-right: 0;
}
.py0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p1 {
  padding: $space-1;
}
.pt1 {
  padding-top: $space-1;
}
.pr1 {
  padding-right: $space-1;
}
.pb1 {
  padding-bottom: $space-1;
}
.pl1 {
  padding-left: $space-1;
}
.py1 {
  padding-top: $space-1;
  padding-bottom: $space-1;
}
.px1 {
  padding-left: $space-1;
  padding-right: $space-1;
}

.p2 {
  padding: $space-2;
}
.pt2 {
  padding-top: $space-2;
}
.pr2 {
  padding-right: $space-2;
}
.pb2 {
  padding-bottom: $space-2;
}
.pl2 {
  padding-left: $space-2;
}
.py2 {
  padding-top: $space-2;
  padding-bottom: $space-2;
}
.px2 {
  padding-left: $space-2;
  padding-right: $space-2;
}

.p3 {
  padding: $space-3;
}
.pt3 {
  padding-top: $space-3;
}
.pr3 {
  padding-right: $space-3;
}
.pb3 {
  padding-bottom: $space-3;
}
.pl3 {
  padding-left: $space-3;
}
.py3 {
  padding-top: $space-3;
  padding-bottom: $space-3;
}
.px3 {
  padding-left: $space-3;
  padding-right: $space-3;
}

.p4 {
  padding: $space-4;
}
.pt4 {
  padding-top: $space-4;
}
.pr4 {
  padding-right: $space-4;
}
.pb4 {
  padding-bottom: $space-4;
}
.pl4 {
  padding-left: $space-4;
}
.py4 {
  padding-top: $space-4;
  padding-bottom: $space-4;
}
.px4 {
  padding-left: $space-4;
  padding-right: $space-4;
}

.p5 {
  padding: $space-5;
}
.pt5 {
  padding-top: $space-5;
}
.pr5 {
  padding-right: $space-5;
}
.pb5 {
  padding-bottom: $space-5;
}
.pl5 {
  padding-left: $space-5;
}
.py5 {
  padding-top: $space-5;
  padding-bottom: $space-5;
}
.px5 {
  padding-left: $space-5;
  padding-right: $space-5;
}

// MARGINS

.m0 {
  margin: 0;
}
.mt0 {
  margin-top: 0;
}
.mr0 {
  margin-right: 0;
}
.mb0 {
  margin-bottom: 0;
}
.ml0 {
  margin-left: 0;
}
.mx0 {
  margin-left: 0;
  margin-right: 0;
}
.my0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m1 {
  margin: $space-1;
}
.mt1 {
  margin-top: $space-1;
}
.mr1 {
  margin-right: $space-1;
}
.mb1 {
  margin-bottom: $space-1;
}
.ml1 {
  margin-left: $space-1;
}
.mx1 {
  margin-left: $space-1;
  margin-right: $space-1;
}
.my1 {
  margin-top: $space-1;
  margin-bottom: $space-1;
}

.m2 {
  margin: $space-2;
}
.mt2 {
  margin-top: $space-2;
}
.mr2 {
  margin-right: $space-2;
}
.mb2 {
  margin-bottom: $space-2;
}
.ml2 {
  margin-left: $space-2;
}
.mx2 {
  margin-left: $space-2;
  margin-right: $space-2;
}
.my2 {
  margin-top: $space-2;
  margin-bottom: $space-2;
}

.m3 {
  margin: $space-3;
}
.mt3 {
  margin-top: $space-3;
}
.mr3 {
  margin-right: $space-3;
}
.mb3 {
  margin-bottom: $space-3;
}
.ml3 {
  margin-left: $space-3;
}
.mx3 {
  margin-left: $space-3;
  margin-right: $space-3;
}
.my3 {
  margin-top: $space-3;
  margin-bottom: $space-3;
}

.m4 {
  margin: $space-4;
}
.mt4 {
  margin-top: $space-4;
}
.mr4 {
  margin-right: $space-4;
}
.mb4 {
  margin-bottom: $space-4;
}
.ml4 {
  margin-left: $space-4;
}
.mx4 {
  margin-left: $space-4;
  margin-right: $space-4;
}
.my4 {
  margin-top: $space-4;
  margin-bottom: $space-4;
}

.m5 {
  margin: $space-5;
}
.mt5 {
  margin-top: $space-5;
}
.mr5 {
  margin-right: $space-5;
}
.mb5 {
  margin-bottom: $space-5;
}
.ml5 {
  margin-left: $space-5;
}
.mx5 {
  margin-left: $space-5;
  margin-right: $space-5;
}
.my5 {
  margin-top: $space-5;
  margin-bottom: $space-5;
}

.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

// RESPONSIVE

.sm-show,
.md-show,
.lg-show {
  display: none !important;
}

@media (min-width: $breakpoint-sm) {
  .sm-show {
    display: block !important;
  }
}
@media (min-width: $breakpoint-md) {
  .md-show {
    display: block !important;
  }
}
@media (min-width: $breakpoint-lg) {
  .lg-show {
    display: block !important;
  }
}
@media (min-width: $breakpoint-sm) {
  .sm-hide {
    display: none !important;
  }
}
@media (min-width: $breakpoint-md) {
  .md-hide {
    display: none !important;
  }
}
@media (min-width: $breakpoint-lg) {
  .lg-hide {
    display: none !important;
  }
}

.display-none {
  display: none !important;
}

.hide {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

// TYPOGRAPHY

.font-family-inherit {
  font-family: inherit;
}
.font-size-inherit {
  font-size: inherit;
}
.text-decoration-none {
  text-decoration: none;
}

.bold {
  font-weight: $font-weight-bold;
}
.regular {
  font-weight: normal;
}
.italic {
  font-style: italic;
}
.caps {
  text-transform: uppercase;
}

.left-align {
  text-align: left;
}
.center {
  text-align: center;
}
.right-align {
  text-align: right;
}
.justify {
  text-align: justify;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.nowrap {
  white-space: nowrap;
}
.break-word {
  word-wrap: break-word;
}

.list-style-none {
  list-style: none;
}
.underline {
  text-decoration: underline;
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
