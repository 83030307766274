@import '../../styles/variables';

.search-results {
  background-color: $color-body-section-background;
  position: absolute;
  z-index: 2;
  padding: 0;
}

.top-search-container {
  display: flex;
}

.search-bar-button-container {
  width: 85%;
  position: relative;
  height: 100%;
  button {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    background-color: $color-text-light;
  }

  input {
    border: none;
    background-color: $color-text-light;
    outline: none;
  }

  .search-control__container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;
  }

  .search-control__input {
    height: 100%;
    width: 100%;
    &:focus {
      border: 0;
    }
  }
}

.autocomplete-input {
  input {
    background-color: #f2f2f2;
    border: 1px solid transparent;
    display: block;
    font-size: 12px;
    height: 100%;
    margin-bottom: 0;
    padding-left: 30px;
    width: 100%;

    &::placeholder {
      color: #888 !important;
    }

    &:hover {
      border: 1px solid transparent !important;
    }

    &:focus {
      outline: none;
      border: 1px solid #e04f00 !important;
      border-right: 1px solid transparent;
      box-shadow: none !important;
    }
  }
}

.search-link {
  vertical-align: text-bottom;
}

#search-controls-fixed {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: $color-text-light;
  z-index: 1100;
  transition: transform 0.4s ease;
  .search-header-fixed {
    display: flex;
    justify-content: space-between;
    button {
      background-color: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      i {
        position: relative;
      }
    }
  }
  .search-controls-fixed-container {
    background-color: $color-secondary;
    font-weight: bold;
    padding: 3%;
    Col {
      width: 100%;
      margin-bottom: 5%;
      color: white;
      label {
        font-size: small;
        display: inline-block;
        input {
          width: 1.5rem;
          vertical-align: middle;
          cursor: pointer;
        }
      }
    }
    .search-controls-button {
      button {
        width: 100%;
      }
    }
  }
  .submit-search {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    &:hover {
      background: $color-primary;
    }
  }
}

.travelers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .travelers__select-travelers {
    width: 48%;
    margin-bottom: 14px;
  }
}

.icon-search {
  color: gray;
  font-size: 14px;
}

.search-result__wrapper {
  border: none !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  margin-top: 12px;

  &::before {
    background-color: #fff;
    box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.2);
    content: '';
    display: block;
    height: 20px;
    left: calc(50% - 20px);
    margin-left: 0px;
    position: absolute;
    top: -7px;
    transform: rotate(45deg);
    width: 20px;
    z-index: -1;
  }
}

.search-result__item {
  align-items: center;
  border-bottom: none !important;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  padding: 0.5rem 1rem !important;

  &:hover {
    background: #ccc;
  }

  &:not(:first-child) {
    border-top: 0.1rem solid #f0f0f0;
  }

  .icon {
    align-items: center;
    color: $color-primary;
    display: flex;
    font-size: 20px;
    height: 20px;
    width: 20px;
  }
}

.search-result {
  flex: 1 1 auto;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  text-align: left;
}

.adornment-wrapper {
  display: flex;
  left: 10px;
  position: absolute;
  top: 8px;

  &__icon {
    height: 14px;
    width: 14px;
  }
}
