@import '../../styles/variables';

.access-modal {
  width: 100vw;
  overflow: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (min-width: $breakpoint-md) {
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 52vw;
    max-width: 750px;
  }
  &__overlay {
    width: 100vw;
    height: 100%;
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
  }
  &__content {
    height: 100vh;
    @media (min-width: $breakpoint-md) {
      height: auto;
      overflow: auto;
    }
    &--step1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 10px 20px;
      span {
        font-size: $font-size-small;
        line-height: 1.4;
        margin-bottom: 8px;
        text-align: left;
      }

      .input-container + div {
        position: relative;
        height: 30px;
        width: 100%;
        display: flex;
        margin: 10px 0 10px 0;
        justify-content: center;
        span {
          padding: 0 10px;
          background-color: #fff;
          display: block;
          text-align: center;
          position: relative;
          top: 3px;
          margin: 0;
        }
        hr {
          position: absolute;
          top: 17%;
          z-index: -1;
          width: 90%;
        }
      }
      &-terms {
        font-size: 13px;
        .terms-tooltip {
          font-size: 13px;
          top: -4px;
          margin: 0;
        }
        u {
          cursor: pointer;
        }
      }
    }
    &--step2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-height: 300px;
      padding: 10px 20px;
      i {
        position: absolute;
        right: 20px;
        top: 20px;
      }
      h3 {
        text-align: center;
      }
      button {
        width: auto !important;
        padding: 15px;
      }
    }
  }
  &__header {
    position: relative;
    @media (min-width: $breakpoint-md) {
      width: 50%;
    }
    img {
      display: block;
      width: 40%;
      margin: 15px auto;
      background-color: #fff;
      padding: 0 10px;
    }
    hr {
      position: absolute;
      top: 35%;
      width: 100%;
      z-index: -1;
    }
  }
  h3 {
    color: $color-primary;
    font-weight: $font-weight-bold;
    font-size: $font-size-h1;
    line-height: 1.2;
    margin: 0 0 10px 0;
  }
  .input-container {
    height: $base-space * 3;
    width: 80%;
    margin-top: 20px;
    display: flex;
    input {
      border: 1px solid $color-background-lighter;
      background: #fff;
      width: 70%;
      transition: 0.2s ease;
      box-shadow: inset 0 0.2rem 0.35em 0 rgba(0, 0, 0, 0.1);
      &:active,
      &:focus,
      &:hover {
        border: 1px solid $color-primary;
        outline: none;
      }
    }
  }
  button,
  .button {
    background-color: $color-primary;
    color: $color-body-section-background;
    border: 1px solid $color-primary;
    transition: 0.2s ease;
    width: 30%;
    &:hover,
    &:focus {
      background-color: $color-primary-hover;
      border-color: $color-primary-hover;
      outline-color: $color-primary-hover;
    }
    &--facebook {
      background-color: #4862a3;
      border-color: #4862a3;
      &:hover,
      &:focus {
        background-color: #4862a3;
        border-color: #4862a3;
        outline-color: #4862a3;
      }
      span {
        color: $color-body-section-background;
      }
      &-icon {
        font-size: 26px;
        height: 32px;
      }
    }
    &--google {
      background-color: #fff;
      color: #000 !important;
      border: solid 1px #cfd8dc;
      &:hover,
      &:focus {
        background-color: #fff;
        color: #000;
        outline-color: #cfd8dc;
      }
      &-icon {
        font-size: 20px;
        height: 26px;
      }
    }
    .next-step-button-icon {
      font-size: 20px;
      height: 20px;
      margin-left: 2px;
      @media (min-width: $breakpoint-md) {
        font-size: 20px;
        height: 18px;
        margin-left: 4px;
      }
    }
  }
  .benefit-lists {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 20px;
    width: 100%;
    @media (min-width: $breakpoint-md) {
      margin-top: 10px;
      flex-direction: row;
    }
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (min-width: $breakpoint-md) {
        &:not(:first-of-type) {
          margin-left: 15px;
        }
      }
      span {
        font-size: $font-size-small;
        display: block;
        width: 40%;
        @media (min-width: $breakpoint-md) {
          width: auto;
        }
      }
      i {
        font-size: 2rem;
        margin-right: 15px;
        color: $color-primary;
      }
    }
  }
  .social-buttons {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    justify-content: space-around;
    @media (min-width: $breakpoint-md) {
      width: 70%;
      justify-content: center;
      margin: 0 auto 30px;
    }
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      margin: 0 5px;
      text-decoration: none;
      @media (min-width: $breakpoint-md) {
        width: 147px;
      }
      &:first-of-type {
        margin-bottom: 25px;
        @media (min-width: $breakpoint-md) {
          margin-bottom: 0;
        }
      }
      span {
        display: block;
        margin: 0 0 0 10px;
        @media (min-width: $breakpoint-md) {
          font-size: 1rem;
        }
      }
    }
  }
  .reviews-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    p {
      height: 30px;
      display: flex;
      justify-content: center;
      margin: 0 8px;
    }
    img {
      margin-left: 4px;
      height: 25px;
    }
  }
}

.lead-box__go-back-button-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #ffffff 50%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  pointer-events: none;
}

.lead-box__go-back-button {
  margin: 0 auto 20px;

  .go-back-button-icon {
    font-size: 20px;
    height: 20px;
    margin-left: 2px;
    @media (min-width: $breakpoint-md) {
      font-size: 20px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

.terms,
.privacy-policy {
  &__content {
    padding: 15px;
    font-size: 80%;
    text-align: left;
    overflow: auto;
    height: 90vh;
    @media (min-width: $breakpoint-md) {
      width: auto;
      margin-left: 15px;
      height: 450px;
    }
    ul {
      padding: 0;
    }
    h3 {
      color: $color-text-primary;
      line-height: 1.5;
    }
    .py5 {
      padding-top: 0;
    }
  }
}

.terms-tooltip__content {
  width: 35vw;
  line-height: 1;
  padding: 10px;
  font-size: 11px;
  background-color: black;
  margin: -10px;
}

//TODO it should be fixed in Falcon
#falcon-floating-message-container {
  z-index: 10000;
}
