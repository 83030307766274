@import '../../styles/variables';
.footer-head {
  background: $color-secondary;
  color: $color-text-light;
  height: 3.125rem;
  width: 100%;
  @media (max-width: 480px) {
    display: none;
  }
}

.footer-body {
  background: $color-background-darker;
  color: $color-text-light;
  font-size: 0.7em;
  padding: 1.875rem 0;
  width: 100%;
  @media (max-width: 480px) {
    display: flex;
  }
}

.footer-content {
  @media (max-width: 480px) {
    width: 75%;
    padding: 0 10px;
  }
}

.footer-ul {
  display: flex;
  padding: 0;
  margin: 0 -5px;
  list-style: none;
  flex-wrap: wrap;
}

.footer-ul-li {
  margin: 0 5px;
  a {
    color: $color-text-light;
    text-decoration: none;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 480px) {
    font-weight: bolder;
  }
}

.footer-bar-ul {
  flex: 1;
  display: flex;
  list-style: none;
  padding-left: 0;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 0.68em;
  word-spacing: 0.4ex;
  margin-top: 0;
  margin-bottom: 0;
  background-color: $color-secondary;
  height: inherit;
}

.footer-bar-ul-li {
  cursor: pointer;
  margin: 0 0.625rem;
  display: flex;
  align-items: center;
  height: 2.18rem;
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  color: $color-text-light;
  font-weight: 700;

  em {
    font-weight: 900;
    font-style: normal;
    color: $color-primary;
  }

  a {
    color: $color-text-light;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    div {
      height: 7.5rem;
    }
  }
}

.footer-right-box {
  display: flex;
  align-items: center;
  bottom: 123%;
  height: 0;
  width: 18.75rem;
  overflow: hidden;
  left: -50%;
  background: $color-background-darker;
  font-weight: 400;
  cursor: default;
  transition: height 0.5s ease;
  position: absolute;
  z-index: 3;
}

.footer-left-box {
  display: flex;
  align-items: center;
  bottom: 123%;
  height: 0;
  width: 300px;
  overflow: hidden;
  left: -125%;
  background: $color-background-darker;
  font-weight: 400;
  cursor: default;
  transition: height 0.5s ease;
  position: absolute;
  z-index: 3;
}

.footer-box-ul {
  padding: 20px 20px 7px;
  width: 100%;
}

.footer-box-ul-li {
  background-size: 0.5rem;
  padding-left: 0.875rem;
  margin-bottom: 0.625rem;
  list-style-type: none;
  a {
    color: $color-primary;
  }
}

.social-media-column {
  @media (max-width: 480px) {
    position: absolute;
    right: 5%;
  }
}

.footer-paragraph {
  line-height: 1.6em;
  text-align: justify;
  color: $color-text-muted-lighter;
  margin-top: 1.25rem;
  @media (max-width: 480px) {
    font-size: small;
  }
}

.social-media-container {
  display: flex;
  a {
    margin-left: 10px;
  }
  @media (max-width: 480px) {
    width: auto;
    svg {
      width: 75%;
      height: auto;
      display: inline;
    }
  }
}

.big-icon {
  font-size: xx-large;
  margin: auto;
}
