@import '../../../styles/variables';

%filter-text {
  color: $color-primary;
  font-size: 12px;
  font-weight: 400;
}

.clear-filters__container {
  display: flex !important;
  margin: 0 10px;
}

.clear-filters__filter {
  background: white !important;
  border: solid 2px $color-primary !important;
  font-size: 12px;
  margin-left: 8px;
  padding: 4px 8px !important;

  div {
    @extend %filter-text;
  }
}

.clear-filters__icon {
  color: $color-primary;
  font-size: 16px;
  margin-left: 4px;
}

.clear-filters__clear-all {
  background: $color-body-section-background !important;
  color: $color-primary;
  margin-left: 8px;
  padding: 0 !important;

  div {
    @extend %filter-text;
  }
}

.clear-filters__no-filters {
  color: $color-text-muted !important;
}
