@import '../../styles/variables';

.trip {
  padding: 0 0 50px 0;
  &__header {
    @media (min-width: $breakpoint-md) {
      padding: 64px;
    }
    h1 {
      font-size: 40px;
      line-height: 1.25;
      letter-spacing: -0.25px;
      font-weight: 400;
    }
    h2 {
      font-size: 24px;
      font-weight: 400;
    }
    .trip-summary {
      margin: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__ref-number {
        color: $color-text-muted;
        font-size: 14px;
      }
    }
  }
  &__badge {
    text-align: right;
    span {
      background: $steel;
      display: inline-block;
      color: #fff;
      padding: 10px;
      font-size: 14px;
    }
  }
  .trip-dates {
    justify-content: center;
    margin: 0 0 20px 0;
    @media (min-width: $breakpoint-md) {
      justify-content: flex-end;
    }
    align-items: center;
    text-align: center;
    &__head {
      font-size: 32px;
      line-height: 1.25;
      letter-spacing: -0.2px;
    }
    &__sub {
      font-size: 14px;
      line-height: 1.5;
      display: block;
      color: $steel-70;
    }
    &__duration {
      align-self: center;
      span {
        justify-content: center;
        background: $steel-5;
        color: $steel-70;
        display: flex;
        border-radius: 4px;
        font-size: 14px;
        padding: 8px;
        white-space: nowrap;
        i {
          font-size: 18px;
          display: flex;
          margin: 0 4px 0 0;
        }
      }
    }
  }
  section {
    padding: 20px;
    @media (min-width: $breakpoint-md) {
      padding: 32px 98px;
    }
  }
  .trip-alert {
    h2 {
      font-weight: normal;
    }
    section {
      border: 1px solid $steel-15;
      margin: 50px 0;
      text-align: center;
    }
    button {
      margin: 10px auto 0;
    }
  }
  .trip-accomodation {
    &__included-services {
      > div {
        margin: 10px 0;
      }
    }
    .facilities-list {
      margin: 0;
      padding: 0;
      li {
        margin: 12px 0;
        display: flex;
        align-items: center;
        i {
          font-size: 20px;
          margin: 0 10px 0 0;
        }
      }
    }
  }
  .accordion {
    &__panel {
      transition: margin 0.3s ease-in-out;
      h2 {
        font-weight: normal;
        font-size: 24px;
        letter-spacing: -0.1px;
        line-height: 1.25;
        display: flex;
        align-items: center;
        i {
          line-height: 0;
          &:first-child {
            color: $color-primary;
            margin-right: 10px;
          }
        }
      }
      .trip-dates {
        justify-content: center;
      }
      section {
        border-bottom: 1px solid $steel-15;
        hr {
          border: 0;
          border-bottom: 1px solid $steel-15;
          margin: 30px 0;
        }
        h4 {
          margin: 0 0 20px 0;
          font-weight: normal;
        }
        h5 {
          margin: 0 0 5px 0;
          font-weight: normal;
        }
      }
      &--open {
        margin: 0 0 30px 0;
        .accordion__chevron {
          transform: rotate(180deg);
        }
      }
    }
    &__chevron {
      color: $color-link;
      font-size: 75%;
      transition: transform 0.3s ease-in-out 0.1s;
      margin-left: auto;
    }
    &__summary {
      padding: 24px;
      @media (min-width: $breakpoint-md) {
        padding: 32px 64px;
      }
    }
    &__content {
      > div {
        padding: 0;
      }
    }
    .trip-participants {
      margin: 20px 0 -1px 0;
      @media (min-width: $breakpoint-md) {
        margin: 80px 0 -1px 0;
      }
    }
  }
}
