@import '../../../../styles/variables';
.card-container {
  width: 21.875rem;
  background: $color-body-background;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
}

.card-image {
  display: none;
  @media (min-width: $breakpoint-sm) {
    display: block;
    height: 198px;
  }
}

.card-content {
  padding: 16px 24px 0;
}

.card-header {
  display: flex;
  align-items: center;
}

.card-icon {
  font-size: 24px;
  width: 32px;
  height: 32px;
  padding-right: 10px;
}

.card-title {
  font-size: 18px;
  line-height: 1.5;
}

.card-description {
  height: 54px;
  overflow: hidden;
  position: relative;
  margin: 10px 0 -20px;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 25%;
    height: 1.5rem;
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0) 0,
      hsla(0, 0%, 100%, 0.01) 1%,
      $color-text-light
    );
  }
  @media (min-width: $breakpoint-md) {
    margin: 10px 0;
    height: 120px;
  }
}
