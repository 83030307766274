@import '../../styles/variables';

.offer-card {
  &:hover {
    text-decoration: none;
    .offer-card__image {
      opacity: 0.9;
    }
  }

  &__image-container {
    position: relative;
  }

  &__image {
    width: 100%;
  }

  &__discount-tag {
    position: absolute;
    top: 20px;
    color: $color-background-light;
    background-color: $color-primary;
    height: 36px;
    width: 15%;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    line-height: 36px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -35px;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent transparent transparent $color-primary;
      border-width: 18px;
      margin-right: 0;
    }
  }

  &__description {
    margin: 12px;
    line-height: 1.25;
  }

  &__title {
    font-size: 17px;
    font-weight: $font-weight-bold;
  }

  &__location {
    font-size: 14px;
  }

  &__tooltip-content {
    font-size: 12px;

    &-price::first-letter {
      text-transform: capitalize;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 18px;
    font-weight: $font-weight-bold;
    vertical-align: middle;
    line-height: 1.5;
    letter-spacing: -0.1px;
    margin-top: 30px;

    .offer-card__price-icon,
    .offer-card__price-from,
    .offer-card__price-value,
    .offer-card__price-discount-old-price {
      margin-right: 6px;
    }

    .offer-card__price {
      .offer-card__tooltip {
        position: relative;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .offer-card__price-icon {
      font-size: 20px;
    }

    .offer-card__price-discount-old-price {
      color: #7f7f7f;
      position: relative;
      display: inline-block;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        height: 1px;
        background-color: $steel-50;
      }
    }

    .offer-card__info-icon {
      font-size: 14px;
      color: #93959f;
    }
  }
}
