@import '../../../styles/variables';

.offer-page-top-title {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 1140px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 0.5rem;

  > .row {
    width: 100%;
  }

  div {
    &.mobile {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .crossed-price {
        display: block;
        text-align: end;
      }

      .subtitle {
        font-size: 0.85rem;
      }

      .title {
        font-size: 1.25rem;
        line-height: 1.5;
        font-weight: normal;
      }

      & #gallery {
        margin: 0;
      }
    }

    & .prices-container {
      display: block;
    }
  }

  .price-button {
    button {
      width: 100%;
      margin-top: 1.5rem;
    }
  }

  @media (min-width: $breakpoint-md) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    padding-bottom: 0;
    padding-top: 0;
    div {
      .mobile {
        .title {
          font-weight: bold;
        }
      }
    }
    .price-button {
      button {
        margin-top: 0;
      }
    }
  }

  @media (min-width: $breakpoint-lg) {
    &.sticky {
      position: sticky;
      top: 0;
      z-index: 5;
      background-color: $color-text-light;
      margin-bottom: 0;
      height: 4.6rem;
    }
    margin-left: auto;
    margin-right: auto;
    div {
      padding: 0;

      & #gallery {
        margin: 0;

        .title {
          font-size: 2rem;
          font-weight: bold;
        }
      }

      & .prices-container {
        display: flex;
      }

      .prices {
        margin: 0.5rem 1rem 0;
      }

      .price-button {
        button {
          width: auto;
          margin: 0;
        }
      }

      & .mobile {
        .title {
          font-size: 2rem;
          font-weight: bold;
        }

        .subtitle {
          font-size: 0.85rem;
        }
      }
    }
  }
}

@keyframes slideup {
  0% {
    bottom: -6.875rem;
  }
  100% {
    bottom: 0;
  }
}

.cta {
  position: relative;
  overflow: hidden;
  width: 18.75rem;
  height: 12.5rem;
  @media (min-width: $breakpoint-md) {
    display: none;
  }
}

.show-cta {
  .offer-cta {
    background: white;
    position: fixed;
    bottom: -6.875rem;
    height: 6.875rem;
    width: 100%;
    left: 0;
    animation-name: slideup;
    animation-delay: 0.5s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    z-index: 6;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
    border-bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 14px;
    text-align: center;
    align-content: stretch;

    .cta-container {
      padding: 0;
    }

    .cta-hotel-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 10px;
      text-align: left;

      .hotel-name {
        line-height: 1.2;
      }

      .hotel-location {
        font-size: 14px;
        color: $steel-30;
      }
    }

    .cta-price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 16px;
      line-height: 1.2;
      white-space: nowrap;

      .price-number {
        color: $color-primary;
        font-weight: bold;
      }

      .discount-percentage {
        color: $color-primary;
        background-color: $color-primary-light;
      }

      .crossed-price {
        color: $steel-30;
        text-decoration: line-through;
      }
    }

    .cta-show-price {
      button {
        width: 100%;
      }
    }
  }
}

.hide-cta {
  display: none;
}

.offer-page-block {
  border-top: 1px solid $steel-10;
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 1140px;
  padding-top: 24px;
  padding-bottom: 24px;

  @media (min-width: $breakpoint-md) {
    border: 1px solid $steel-10;
    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 0;
    padding-top: 0;
  }

  @media (min-width: $breakpoint-lg) {
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    clear: both;
    margin-bottom: 1.875rem;
    font-size: 22px;
    font-weight: 400;

    @media (min-width: $breakpoint-md) {
      margin-right: auto;
    }

    &--exlc,
    &--accommodation {
      font-size: 18px;
    }
  }
}

.offer-page-block--incl-excl {
  border: 1px solid $color-primary;

  .offer-page-block-excl {
    padding-bottom: 0;
    padding-top: 0;

    .list {
      margin: 0 10px;
    }

    .list__item {
      &::before {
        content: '\2022';
        display: inline-block;
        left: 0;
        position: absolute;
        text-align: center;
        width: 8px;
      }
    }

    .list__item-text {
      font-size: 14px;
    }
  }

  .list {
    column-count: 1;
    margin: 0 10px 28px;
    padding: 0;

    @media (min-width: $breakpoint-md) {
      column-count: 2;
      margin: 0 10px;
    }
  }

  .list__item {
    display: block;
    margin-bottom: 4px;
    padding-left: 16px;
    position: relative;

    .icon {
      display: inline-block;
      left: 0;
      position: absolute;

      &::before {
        border-radius: 50%;
        color: green;
        display: inline-block;
        height: 14px;
        width: 14px;
      }
    }
  }

  .list__item-text {
    display: inline-block;
    font-size: 16px;
    margin-left: -13px;
    padding-left: 20px;
  }
}

.offer-page-block__button-container {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  padding: 24px 0;

  @media (min-width: $breakpoint-md) {
    margin: 0 auto;
    max-width: 926px;
  }

  .icon {
    align-items: center;
    display: flex;
    font-size: 26px;
    margin-left: 8px;
  }
}

.offer-page-block--table {
  border-bottom: none;
  display: block;
  margin-bottom: 0;

  @media (min-width: $breakpoint-md) {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  .offer-page-block__wrapper {
    flex-basis: 50%;
  }

  .offer-page-block__wrapper--accommodation {
    margin-top: 20px;
    padding-top: 28px;
    @media (min-width: $breakpoint-md) {
      border-right: 1px solid $steel-10;
    }
  }

  .modal-trigger {
    color: $color-primary;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      color: $color-primary-hover;
    }
  }

  .offer-page-block__facilities-container {
    margin-top: 50px;
  }

  .offer-page-block__facilities-container {
    .offer-page-block__facilities-list {
      column-count: 2;
      padding: 0;
      font-size: 12px;
      margin: 0 10px;

      @media (min-width: $breakpoint-sm) {
        margin: 0 10px;
        font-size: 14px;
      }
    }

    .offer-page-block__facilities-list-item {
      .icon {
        font-size: 20px;
        margin-right: 14px;
      }
    }
  }

  .offer-page-block__title--rooms {
    margin-bottom: -5px;
  }

  .orange-unordered-list {
    column-count: 1;
  }

  .info-list__row {
    border-bottom: 1px solid $steel-10;
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;

    &:last-child {
      border-bottom: none;
    }

    .modal-button {
      margin: 4px 0 0 calc(40% + 10px);
    }
  }

  .info-list__label {
    flex-basis: 40%;
    padding-right: 10px;
    @media (max-width: $breakpoint-sm) {
      color: $color-text-muted-lighter-purple;
    }
  }

  .info-list__value {
    flex-basis: 60%;
    padding: 0 10px;
    white-space: pre-wrap;
  }
}

.more-info-modal__wrapper {
  position: relative;

  @media (min-width: $breakpoint-md) {
    margin: 32px auto 0 auto;
    width: 46rem;
  }

  .more-info-modal__close-button-wrapper {
    height: 72px;
    position: absolute;
    top: 0;
    width: 100%;

    @media (min-width: $breakpoint-md) {
      align-items: center;
      display: flex;
      height: 32px;
      justify-content: center;
      right: -15px;
      top: -15px;
      width: 32px;
    }
  }

  .more-info-modal__close-button {
    appearance: none;
    background-color: $color-text-light;
    border-width: 0;
    border-bottom: 1px solid $steel-10;
    display: block;
    height: 72px;
    text-align: left;
    width: 100%;

    @media (min-width: $breakpoint-md) {
      background-color: $steel-50;
      border-radius: 50%;
      border-width: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      height: 100%;
      transition: background 0.2s ease-in-out;

      &:hover {
        background: $color-primary;
      }
    }

    .icon {
      display: none;

      @media (min-width: $breakpoint-md) {
        color: $color-text-light;
        display: inline;
        text-align: center;
        font-size: 24px;
      }
    }

    .text {
      align-items: center;
      color: $color-primary;
      display: flex;
      font-weight: bold;
      padding: 16px 0 0 32px;

      .chevron-icon {
        display: inline-block;

        path {
          fill: $color-primary;
        }
      }

      @media (min-width: $breakpoint-md) {
        display: none;
      }
    }
  }

  .more-info-modal__content {
    background-color: $color-text-light;
    margin: 0;
    padding: 16px 24px;
    padding-top: 88px;

    @media (min-width: $breakpoint-md) {
      padding: 48px 56px;
    }
  }
}

.offer-page-block__wrapper--rooms {
  border: none;
  flex-basis: 50%;
  padding: 0 16px;
  margin-top: 40px;

  @media (min-width: $breakpoint-md) {
    padding: 48px 64px 0;
    margin-top: 0;
  }
}

.offer-page-block__wrapper {
  padding-right: 16px;
  padding-left: 16px;

  @media (min-width: $breakpoint-md) {
    padding: 48px 64px;
  }
}

.offer-page-block--destination-info {
  &-tips-container {
    margin-bottom: 40px;
  }

  &-tips {
    p {
      margin: 0;
    }
  }

  &-title {
    font-size: 24px;
    font-weight: $font-weight-normal;
    margin-top: 10px;
  }

  &-content {
    padding-bottom: 24px;

    p {
      margin: 0;
      padding: 24px 0;
      border-bottom: 1px solid #e9e9eb;
    }
  }

  .offer-page-block__wrapper:first-child {
    padding-bottom: 0;
  }

  .offer-page-block__wrapper:nth-child(2) {
    padding-bottom: 0;
    padding-top: 0;
  }

  .offer-page-block__wrapper:last-child {
    padding-top: 0;
  }
}

.room {
  padding: 30px;
  border-bottom: 1px solid $steel-10;

  &:last-child {
    border-bottom: 0;
  }

  @media (min-width: $breakpoint-md) {
    margin: 0 -30px;
  }

  .room__basic-info {
    display: flex;

    .room__image {
      width: 50%;
      height: 50%;
    }

    .room__description {
      margin-left: 10px;
      font-size: 14px;

      .room__title {
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
  }

  .room__facilities {
    column-count: 2;
    padding: 0;
    font-size: 14px;
    margin-top: 20px;

    .room__facilities-item {
      display: flex;
      align-items: center;

      .icon {
        font-size: 20px;
        width: 32px;
      }
    }
  }
}

.offer-suggestions {
  width: 100%;
  background-color: #fffcf3;
  padding: 30px 26px;

  @media (min-width: $breakpoint-sm) {
    padding: 48px 30px;
  }

  &__container {
    max-width: 1140px;
    overflow: hidden;

    @media (min-width: $breakpoint-md) {
      margin: 0 auto;
    }
  }

  &__items {
    display: flex;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 1rem;
    }

    flex: 1 0 auto;
    width: 90%;

    @media (min-width: $breakpoint-sm) {
      width: 50%;
    }

    @media (min-width: $breakpoint-md) {
      flex: 1;
      &:not(:last-child) {
        margin-right: 1.6rem;
      }
    }
  }
}
