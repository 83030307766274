@import '../../styles/variables';

.channels-links {
  display: flex;
  flex-wrap: wrap;

  .channels-image {
    display: none;
  }

  .channels-button {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }

  .more-channels-links {
    display: none;

    &.show {
      display: block;
      position: absolute;
      top: 8rem;
      right: 2rem;
      background: #fff;
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
      z-index: 3;
      width: 10rem;
    }
  }

  @media (min-width: $breakpoint-md) {
    margin: 0 -0.25rem;
    .channel {
      width: 12.5%;
      padding-bottom: 12.5%;
      position: relative;
      overflow: hidden;
    }
    a {
      display: block;
      position: absolute;
      overflow: hidden;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: 0.25rem;
    }
    .channels-image {
      height: 100%;
      width: auto;
      object-fit: cover;
      display: block;
    }
    .more-channels-links {
      display: contents;
    }
    button {
      display: none;
    }
  }
}

.channels-title {
  padding: 0 0.2rem;
  @media (min-width: $breakpoint-md) {
    font-weight: bold;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    padding: 0.75rem 0.5rem;
    color: $color-text-light;
    text-align: center;
    word-wrap: break-word;
    line-height: 1.5;
    letter-spacing: -0.1px;
    ::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      z-index: -1;
      background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(40, 43, 64, 0)),
        to($color-background-purple-dark)
      );
      background-image: -webkit-linear-gradient(
        top,
        rgba(40, 43, 64, 0),
        $color-background-purple-dark
      );
      background-image: linear-gradient(
        to bottom,
        rgba(40, 43, 64, 0),
        $color-background-purple-dark
      );
      opacity: 0.6;
      border-radius: 0 0 2px 2px;
    }
  }
}

.channels-text {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  @media (max-width: 480px) {
    display: none;
  }
}
