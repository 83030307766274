.urgency-container {
  span {
    display: flex;
    flex-wrap: wrap;
    margin: 7px 0;
    strong: {
      font-weight: bold;
    }
  }
  i {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 18px;
  }
}
