@import '../../../styles/variables';

.container-button {
  padding: 2rem;
  margin: auto;
}

.offer-block {
  margin: 0 0 1rem;
}

.offer-block-list {
  column-count: 2;
}

.label {
  text-align: left;
  padding: 1rem 0;
}

.value {
  padding: 1rem 0;
}

.unordered-list {
  column-count: 1;
  list-style: none;
  padding: 0;
  li {
    display: flex;
  }
}

.orange-unordered-list {
  column-count: 2;
  list-style: none;
  padding: 0;
  li {
    padding: 1rem 0;
    &:before {
      content: '\2022';
      color: $color-primary;
      font-weight: bold;
      display: inline-block;
      width: 0.5rem;
      padding-right: 1rem;
    }
  }
}

.primary-button-container {
  display: inline-flex;
  padding: 1rem;
  background: $color-primary;
  color: white;
  justify-content: center;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
  align-items: center;
  &:hover {
    background: $color-primary-red;
  }
  div {
    display: flex;
    margin-top: -1px;
    margin-left: 3px;
    margin-right: 3px;
    align-items: center;
  }
}
