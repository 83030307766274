@import '../../styles/variables';

.auth-modal {
  width: 100vw;
  overflow: scroll;
  background-color: #fff;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (min-width: $breakpoint-md) {
    margin: 5% 0 5% 50%;
    transform: translateX(-50%);
    width: 600px;
  }
  &__overlay {
    width: 100vw;
    height: 100%;
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
  }
  &__content {
    width: 75%;
    padding: 0.5rem 0 2rem 0;
  }
  &__header {
    position: relative;
    width: 80%;
    img {
      display: block;
      width: 40%;
      margin: 15px auto;
      background-color: #fff;
      padding: 0 10px;
    }
    hr {
      position: absolute;
      top: 35%;
      width: 100%;
      z-index: -1;
    }
  }
  &__submit {
    width: 100% !important;
  }
  &__close {
    position: absolute;
    right: -3rem;
    top: 50%;
    margin: -0.75rem 0 0 0;
    cursor: pointer;
    line-height: 1;
    i {
      font-size: 1.5rem;
    }
  }
  &__error {
    background: $color-primary-red;
    color: #fff;
    display: block;
    padding: 0.5rem;
  }
}
