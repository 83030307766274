@import '../../styles/variables'; // TODO: fix this

.header {
  background: $color-text-light;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.168);
  .header-logo {
    display: flex;
    img {
      width: 10rem;
      @media (max-width: 480px) {
        width: 8rem;
      }
    }
  }
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.88rem 0;
    @media (max-width: 480px) {
      justify-content: left;
    }
  }
  nav {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      li {
        margin: 0 15px;
        font-weight: bolder;
        a {
          position: relative;
          display: flex;
          align-items: center;
          flex-direction: row;
          text-decoration: none;
          color: $color-primary;
          &:hover {
            text-decoration: underline;
          }
          &.link-icon {
            padding-left: 1.5rem;
            i {
              position: absolute;
              font-size: 1.25rem;
              left: 0;
            }
          }
        }
      }
    }
  }
  .header-search {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 340px;
    position: relative;
    width: 25rem;
    .top-search-input {
      border: 1px solid transparent;
      background: $color-background-light;
      max-width: 340px;
      width: 25rem;
      &:active,
      &:focus {
        border: 1px solid $color-primary;
        outline: none;
        box-shadow: none;
      }
      @media (max-width: 480px) {
        width: 10rem;
      }
    }
    .header-icon {
      position: absolute;
      font-size: 1.25rem;
      left: 0.4rem;
      top: 35%;
      margin-top: -0.63rem;
    }
    @media (max-width: 480px) {
      width: auto;
      margin: 0 1rem;
    }
  }
}
