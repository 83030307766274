@import '../../styles/variables';

.cms-page {
  &__container {
    max-width: 736px;
  }
  &__title {
    text-align: center;
    font-size: 64px;
    line-height: 1.25;
    letter-spacing: -0.5px;
  }
  &__body {
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: -0.1px;
  }
  &__download {
    margin: 0 auto 50px;
    display: block;
    background-color: $color-primary;
    width: 168px;
    color: #ffff;
    min-height: 51px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    font-weight: bold;
    &:hover,
    &:focus {
      background-color: $color-primary-hover;
    }
  }
}
