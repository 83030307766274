@import '../../styles/variables';
.column {
  height: auto;
  width: 20rem;
  div {
    margin-bottom: 1rem;
    :last-of-type {
      margin-bottom: 0;
    }
  }
  p {
    font-size: small;
    margin: 0;
  }
  span {
    display: block;
    font-weight: bold;
  }
  select {
    width: 100%;
    height: 2rem;
  }
  textarea {
    width: 100%;
    height: 3rem;
  }
}

.text-column {
  width: 20rem;
  textarea {
    width: 100%;
    height: 10.5rem;
  }
}

.sort {
  width: 50%;
  margin-bottom: 1rem;
  background-color: $color-light-border;
}
