.modal-inner__container {
  background-color: rgba(40, 43, 64, 0.7);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
}

.modal-inner__content {
  overflow-y: scroll;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
}
