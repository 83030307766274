@import '../../styles/variables';

.favorite-wrapper {
  position: absolute;
  right: 15px;
  top: 15px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-background-light;
  &:hover,
  &.liked {
    transition: 0.5s;
    background-color: $color-primary;
    i {
      color: $color-text-light !important;
    }
  }
  i {
    line-height: 24px;
    height: 22px;
    font-size: 26px;
  }
  transition: 0.5s;
}
