@import '../../../styles/variables';

.banner-container {
  width: 100%;
  margin: 1rem 0;
  @media (max-width: 480px) {
    display: none;
  }
}

.banner-image {
  width: 100%;
}

.channels-container {
  width: 100%;
  margin: 1rem 0;
}

.show-all-container {
  color: $color-primary;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  i {
    margin: auto;
  }

  @media (max-width: 480px) {
    display: none;
  }
}

.show-all-text {
  padding-right: 0.8rem;
}

.offers-count-container {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 1.5rem 0;
  justify-content: space-between;
  font-weight: bold;
  vertical-align: center;
  @media (max-width: 480px) {
    flex-flow: row wrap;
  }

  span {
    color: $color-primary;
    font-size: 1.2rem;
    margin-left: 4px;
  }

  div {
    align-items: center;
    display: table;
    @media (max-width: 480px) {
      width: 100%;
    }
  }

  select {
    width: 50%;
    margin: 0 0 0 0.5rem;
    border: none;
    background-color: $color-light-border;
    color: $color-text-muted;
  }

  .offers-tags {
    border: 2px solid $color-primary;
    margin: 0 auto 0 0.5rem;
    padding: 0.3rem 0.5rem;
    display: flex;
    align-items: center;
    background-color: transparent;

    span {
      i {
        margin-left: 0.5rem;
        cursor: pointer;
      }
    }

    input {
      border: none;
      color: $color-primary;
      background-color: transparent;
      margin-left: 0.3rem;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        outline: none;
      }
    }

    @media (max-width: 480px) {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
}

.search-input {
  width: 100%;
  padding: 1rem;
  border: 1px solid $color-light-border;
}

.tags-section {
  background-color: $color-text-light;
  padding: 0 0.5rem;
}

.row-container {
  padding: 2rem 0;
  border: none;
}

.row-container-border {
  padding: 30px;
  margin-bottom: 0;
  border: 1px solid $color-light-border;
}

.label {
  text-align: left;
  padding: 1rem 0;
}

.value {
  padding: 1rem 0;
}

.offer-block {
  border: none;
  height: auto;
  padding: 30px;
  display: flex;

  img {
    margin-right: 1.5rem;
  }

  span {
    font-weight: bolder;
  }
}

.offer-block-list {
  column-count: 2;
  padding-top: 1.5rem;
}

.orange-border {
  border: 1px solid $color-primary;
}

.center {
  display: flex;
  padding: 2rem;
  text-align: center;
}

.unordered-list {
  list-style: none;
  padding: 0;
}

.orange-unordered-list {
  column-count: 2;
  list-style: none;
  padding: 0;

  li {
    padding: 1rem 0;

    &:before {
      content: '\2022';
      color: $color-primary;
      font-weight: bold;
      display: inline-block;
      width: 0.5rem;
      padding-right: 1rem;
    }
  }
}

.primary-button-container {
  display: inline-flex;
  padding: 1rem;
  background: $color-primary;
  color: $color-text-light;
  justify-content: center;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
  align-items: center;

  &:hover {
    background: $color-primary-red;
  }

  div {
    display: flex;
    margin-top: -1px;
    margin-left: 3px;
    margin-right: 3px;
    align-items: center;
  }
}

.search-filter-container {
  ul {
    padding: 0.5rem;
    margin: 0;

    li {
      padding: 0;
    }
  }
}

.autocomplete-results {
  li {
    display: flex;

    :hover {
      background-color: $color-body-section-background;
    }
  }

  button {
    background-color: white;
    border: none;
    width: 100%;
    line-height: 1.1;
    height: auto;
    margin: 0;
    cursor: pointer;
    text-align: center;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:focus {
      outline: none;
    }
  }

  .close-button-container {
    margin: auto;

    .close-button {
      border: none;
      height: 2rem;

      &:focus {
        outline: none;
      }
    }
  }

  .clear-tags {
    span {
      color: $color-primary;
    }
  }
}
