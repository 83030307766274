@import '../../styles/variables';

@mixin button-gradient($position, $opacity) {
  background: linear-gradient($position, transparent 0, rgba(0, 0, 0, $opacity)) transparent;
}

.carousel-container {
  position: relative;
}

.slider {
  position: relative;
  overflow: hidden;
  .carousel__slider-tray {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
    transition: transform 500ms;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    will-change: transform;
    &.carousel__slider-tray--horizontal {
      .carousel__slide {
        float: left;
      }
    }
  }
}

.default-slide {
  position: relative;
  display: block;
  box-sizing: border-box;
  height: 0;
  margin: 0;
  list-style-type: none;
}

.slide {
  @extend .default-slide;
  .carousel__inner-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.slide-thumbnail {
  @extend .default-slide;
  opacity: 0.5;
  cursor: pointer;
  &.carousel__slide-active {
    opacity: 1;
    .carousel__image {
      outline: 1px solid $color-primary;
    }
  }
  .carousel__inner-slide {
    padding: 4px 1px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 480px) {
    display: none;
  }
}

.carousel-provider {
  position: relative;
}

.carousel-arrow {
  color: $color-text-light;
  height: 20px;
  display: block;
  font-size: 1.43rem;
}

.carousel-button {
  cursor: pointer;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  &:active,
  &:focus {
    outline: none;
  }
  &[disabled] {
    display: none;
  }
}

.button-back {
  @extend .carousel-button;
  @include button-gradient(270deg, 0.6);
  height: 80px;
  width: 60px;
  left: 0;
  &:hover {
    @include button-gradient(270deg, 0.8);
  }
}

.button-back-thumbnail {
  @extend .carousel-button;
  @include button-gradient(270deg, 0.6);
  width: 30px;
  height: 40px;
  left: 0;
  &:hover {
    @include button-gradient(270deg, 0.8);
  }
  @media (max-width: 480px) {
    display: none;
  }
}

.button-next-thumbnail {
  @extend .carousel-button;
  @include button-gradient(90deg, 0.6);
  width: 30px;
  height: 40px;
  right: 0;
  &:hover {
    @include button-gradient(90deg, 0.8);
  }
  @media (max-width: 480px) {
    display: none;
  }
}

.button-next {
  @extend .carousel-button;
  @include button-gradient(90deg, 0.6);
  height: 80px;
  width: 60px;
  right: 0;
  &:hover {
    @include button-gradient(90deg, 0.8);
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
}
