@import '../../../styles/variables';

.offer-block--navbar {
  &.sticky {
    position: sticky;
    top: 4.5rem;
    z-index: 5;
    background-color: white;
  }
  &.no-border {
    border: none;
    background: none !important;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0 40px;
    .active {
      color: #ff6714;
    }
    li {
      cursor: pointer;
      &::marker {
        color: black;
      }
      &:hover {
        &:not(.active) {
          color: #ff6714;
        }
      }
    }
    @media (min-width: $breakpoint-md) {
      list-style: initial;
      list-style-position: outside;
      li:not(:first-child) {
        padding-left: 30px;
      }
      li:first-child {
        list-style-type: none;
      }
    }
  }
}
